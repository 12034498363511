import { Message } from "element-ui"; //项目已经全局引入element的话可以不单独引入
import router from "@/router";
import { startLoading, endLoading } from "@/utils/function";
import API from '@/api/teacher/center/memberList.js';
import { nprogressStart, nprogressClose } from "@/plugins/nprogress.js";
export default {
    // 页面名称
    name: "colleague",
    data()
    {
        return{
            startDate:"",
            endDate:"",
        }
    },
    methods:
    {
        getData()
        {
            console.log("getData");
        },
    }
};