<template >
    <div class="logList">
        <div class="title">
            虚拟实验数据导出
        </div>
        <div class="block">
            <span class="demonstration">开始日期</span>
            <el-date-picker
            v-model="startDate"
            type="date"
            placeholder="选择日期">
            </el-date-picker>
        </div>
        <div class="block">
            <span class="demonstration">结束日期</span>
            <el-date-picker
            v-model="endDate"
            type="date"
            placeholder="选择日期">
            </el-date-picker>
        </div>
        <div class="block">
            <el-button type="primary" @click="getData" class="button">导 出 excel</el-button>
        </div>
    </div>
</template>

<script>
    // 引入试题列表js
    import logList from "@/assets/js/teacher/center/tongji.js";
    export default {
        ...logList
    }
</script>

<style lang="scss" scoped>
// 引入试题列表组件css
    @import "@/assets/css/teacher/center/tongji.scss";
</style>