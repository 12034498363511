import { request } from "@/request/http";

export default {
  showCenterList: (data) => {
    return request("POST", `/index.php/center/showCenterList`, data);
  },

  searchMember: (data) => {
    return request("POST", `/index.php/center/searchMember`, data);
  },

  addMember: (data) => {
    return request("POST", `/index.php/center/addMember`, data);
  },

  deleteMember: (data) => {
    return request("POST", `/index.php/center/deleteMember`, data);
  },

}
